import {messageScript} from './env-specific';

declare global {
  const initGsMessage: (s?: string, loc?: string) => void;
}
export default function initializeGSMessages(loc?: string) {
  const envParams = messageScript();
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.crossOrigin = 'anonymous';
  script.defer = true;
  script.src = envParams.url;
  script.integrity = envParams.integrity;
  document.body.appendChild(script);
  // script.onerror = () => {};
  script.onload = () => {
    if (typeof initGsMessage !== 'undefined') {
      const msgType = envParams.msgEnv;
      if (msgType) {
        initGsMessage(msgType.toLowerCase(), loc?.toUpperCase());
      }/* else {
        console.error('GSM init failed. (msgType)');
      }*/
    }/* else {
      console.error('GSM init failed. (src)');
    }*/
  };
}
