import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
export interface Region {
  code: string;
  name: string;
}

/**
 * A special marker function for extracting translation keys from source code.
 * Used by ngx-translate-extract via npm scripts defined in package.json.
 * As translation keys are 'namespaced' we must include the namespace in the call
 * for the string parser. To avoid having to define the values twice, that
 * namespacing is stripped from the return value, leaving us with an array of
 * pure values.
 *
 * @param s Array of translation keys.
 * @returns  Array of translation keys with namespacing stripped.
 */
function stripPrefix(s: string[]): string[] {
  return s.map(i => i.split('.').pop());
}

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  /**
   * List of region codes. The 'region-name' prefix is stripped from each
   * value by the translation marker function call and stripPrefix
   * leaving us with ['BE-NDL', 'BE-FRA', ...] as values and 'region-name.BE-NDL'
   * entries in localisation resources. The region codes include the country part
   * to allow for separate labels with same actual region code, e.g. 'FRA'. The
   * country part needs to be stripped from values when assiging to user.
   */
  private static regionCodes = {
    BE: stripPrefix(_([
      'region-name.BE-NDL', 'region-name.BE-FRA',
    ])),
    CH: stripPrefix(_([
      'region-name.CH-FRA', 'region-name.CH-IDC', 'region-name.CH-ITA',
    ])),
  };
  constructor(
    private translate: TranslateService
  ) {}

  public alphabetical(cc: string): Observable<Array<Region>> {
    return new Observable<Array<Region>>(subscriber => {
      this.list(cc).subscribe(res => {
        subscriber.next(res?.sort((a: Region, b: Region) => a.name.localeCompare(b.name)));
        subscriber.complete();
      });
    });
  }

  public list(cc: string): Observable<Array<Region>> {
    return new Observable<Array<Region>>(subscriber => {
      if (!!RegionService.regionCodes[cc]) {
        const regionsObs = forkJoin(RegionService.regionCodes[cc].map(code => this.createRegionItem(code)));
        regionsObs.subscribe((res: Region[]) => {
          subscriber.next(res);
          subscriber.complete();
        });
      } else {
        subscriber.next(null);
        subscriber.complete();
      }
    });
  }

  public getRegionName(code: string): Observable<string> {
    return new Observable<string>(subscriber => {
      // assign the `translate` in to variable, as `this.translate.get(` is used when extracting strings,
      // and won't work with dynamic the `code`
      const trans = this.translate;
      trans.get('region-name.' + code).subscribe(res => {
        subscriber.next(res);
        subscriber.complete();
      });
      subscriber.next(code);
      subscriber.complete();
    });
  }

  private createRegionItem(code: string): Observable<Region> {
    return new Observable<Region>(subscriber => {
      this.getRegionName(code).subscribe(res => {
        subscriber.next(
          {
            code: code,
            name: res,
          });
        subscriber.complete();
      });
    });
  }

}
