<ng-template
  #confirmUnsavedChangesNavigation
>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title"
    >{{'edit-profile.unsaved-changes-dialog.title'|translate}}</h4>
    <button
      type="button"
      class="close"
      attr.aria-label="{{'edit-profile.unsaved-changes-dialog.close-button.aria-label'|translate}}"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{'edit-profile.unsaved-changes-dialog.info-paragraph'|translate}}
    </p>
  </div>
  <div class="modal-footer flex-row-reverse justify-content-between">
    <button
      data-test-discard-changes
      type="button"
      class="btn btn-danger"
      ngbAutofocus
      (click)="modal.close('discard')"
    >{{'edit-profile.unsaved-changes-dialog.discard-button.label'|translate}}</button>
    <button
      data-test-cancel-navigation
      type="button"
      class="btn btn-secondary"
      (click)="modal.close('cancel')"
    >{{'edit-profile.unsaved-changes-dialog.cancel-button.label'|translate}}</button>
  </div>
</ng-template>
<ng-template
  #delegatedAuthenticationInfoBox
>
  <app-duke-alert
    type="info"
    alertClass="text-left mt-3 mb-0"
    *ngIf="hasDelegatedAuthentication"
  >
    <h2 class="h6 font-weight-bold">
      {{'edit-profile.third-party-auth-info-box.title'|translate}}
    </h2>
  </app-duke-alert>
</ng-template>
<div
  class="card standalone fade edit-profile-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <div class="card-header text-center">
    <h1 class="mb-0">
      {{'edit-profile.title'|translate}}
    </h1>
    <ng-container *ngTemplateOutlet="delegatedAuthenticationInfoBox"></ng-container>
  </div>
  <form
    [formGroup]="profileForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="card-body">
      <ul
        class="nav nav-tabs nav-fill"
        *ngIf="profile"
      >
        <li class="nav-item">
          <a
            data-test-profile-tab
            class="nav-link d-flex align-items-center flex-row"
            [ngClass]="{
              'active':  activeTab === 'profile'
            }"
            tabindex="0"
            role="tab"
            placement="auto"
            [ngbTooltip]="profileForm.get('personal').dirty ? ('edit-profile.personal-tab.tooltip.unsaved'|translate) : ('edit-profile.personal-tab.tooltip.default'|translate)"
            (keyup.enter)="changeTab('profile')"
            (click)="changeTab('profile')"
          >
            <span class="icon highlight fa fa-2x fa-fw fa-user"></span>
            <span
              class="tab-notification fa fa-exclamation-circle rounded-circle text-warning border"
              *ngIf="profileForm.get('personal').dirty"
            ></span>
            <div class="tab-label">
              {{'edit-profile.personal-tab.label'|translate}}
            </div>
          </a>
        </li>
        <li class="nav-item" *ngIf="tabs.indexOf('contact') >= 0">
          <a
            data-test-contact-tab
            class="nav-link d-flex align-items-center flex-row"
            [ngClass]="{
              'active':  activeTab === 'contact'
            }"
            tabindex="0"
            role="tab"
            placement="auto"
            [ngbTooltip]="profileForm.get('contact').dirty ? ('edit-profile.contact-tab.tooltip.unsaved'|translate) : ('edit-profile.contact-tab.tooltip.default'|translate)"
            (keyup.enter)="changeTab('contact')"
            (click)="changeTab('contact')"
          >
            <span class="icon highlight fa fa-2x fa-fw fa-address-book"></span>
            <span
              class="tab-notification fa fa-exclamation-circle rounded-circle bg-white text-warning border border-white"
              *ngIf="profileForm.get('contact').dirty"
            ></span>
            <div class="tab-label">
              {{'edit-profile.contact-tab.label'|translate}}
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a
            data-test-login-tab
            class="nav-link d-flex align-items-center flex-row"
            [ngClass]="{
              'active':  activeTab === 'login'
            }"
            tabindex="0"
            role="tab"
            placement="auto"
            ngbTooltip="{{'edit-profile.login-tab.tooltip'|translate}}"
            (keyup.enter)="changeTab('login')"
            (click)="changeTab('login')"
          >
            <span class="icon highlight fa fa-2x fa-fw fa-user-shield"></span>
            <span
              class="tab-notification fa fa-exclamation-circle rounded-circle bg-white text-warning border border-white"
              *ngIf="profileForm.get('login').dirty"
            ></span>
            <div class="tab-label">
              {{'edit-profile.login-tab.label'|translate}}
            </div>
          </a>
        </li>
      </ul>
      <div
        #tabHolder
        class="tab-content"
      >
        <div #profileTab id="profile" class="tab-pane {{activeTab === 'profile' ? 'active' : ''}}">
          <fieldset
            class="pt-3"
            [formGroup]="profileForm.get('personal')"
          >
            <p class="text-info d-flex flex-row px-2"
               *ngIf="hasDelegatedAuthentication"
            >
              <span class="fa fa-fw icon fa-info-circle mt-1 mr-2"></span>
              {{'edit-profile.profile-details-disabled'|translate}}
            </p>
            <ng-container *ngFor="let field of personalFields">
              <div
                *ngIf="field.key === 'firstName'"
                app-form-input
                field="firstName"
                autocomplete="given-name"
                label="{{'edit-profile.first-name.label'|translate}}"
                [readonly]="field.readonly"
                [isInvalid]="isFieldInvalid('personal.firstName')"
                [isValid]="isFieldValid('personal.firstName')"
              >
                <div
                  app-form-input-feedback
                  type="invalid"
                >{{'edit-profile.first-name.error-message.required'|translate}}</div>
              </div>
              <div
                *ngIf="field.key === 'lastName'"
                app-form-input
                field="lastName"
                autocomplete="family-name"
                label="{{'edit-profile.last-name.label'|translate}}"
                [readonly]="field.readonly"
                [isInvalid]="isFieldInvalid('personal.lastName')"
                [isValid]="isFieldValid('personal.lastName')"
              >
                <div
                  app-form-input-feedback
                  type="invalid"
                >{{'edit-profile.last-name.error-message.required'|translate}}</div>
              </div>
              <div
                *ngIf="field.key === 'nickname'"
                app-form-input
                field="nickname"
                autocomplete="nickname"
                label="{{'edit-profile.nickname.label'|translate}}"
                [readonly]="field.readonly"
                [isInvalid]="isFieldInvalid('personal.nickname')"
                [isValid]="isFieldValid('personal.nickname')"
              >
                <div
                  app-form-input-feedback
                  type="invalid"
                >{{'edit-profile.nickname.error-message.required'|translate}}</div>
              </div>
              <div
                *ngIf="field.key === 'preferredUsername'"
                app-form-input
                field="preferredUsername"
                autocomplete="name nickname"
                label="{{'edit-profile.display-name.label'|translate}}"
                [readonly]="field.readonly"
                [isInvalid]="isFieldInvalid('personal.preferredUsername')"
                [isValid]="isFieldValid('personal.preferredUsername')"
              >
                <div
                  app-form-input-feedback
                  type="invalid"
                >{{'edit-profile.display-name.error-message.required'|translate}}</div>
              </div>
              <div
                *ngIf="field.key === 'professionalTitle'"
                app-form-input
                field="professionalTitle"
                autocomplete="organization-title"
                label="{{'edit-profile.professional-title.label'|translate}}"
                [readonly]="field.readonly"
                [isInvalid]="isFieldInvalid('personal.professionalTitle')"
                [isValid]="isFieldValid('personal.professionalTitle')"
              >
                <div
                  app-form-input-feedback
                  type="invalid"
                >{{'edit-profile.professional-title.error-message.required'|translate}}</div>
              </div>
            </ng-container>
          </fieldset>
        </div>
        <div #contactTab id="contact" class="tab-pane  {{activeTab === 'contact' ? 'active' : ''}}" *ngIf="tabs.indexOf('contact') >= 0">
          <fieldset
            class="pt-3"
            [formGroup]="profileForm.get('contact')"
          >
            <p class="text-info d-flex flex-row px-2"
               *ngIf="hasDelegatedAuthentication"
            >
              <span class="fa fa-fw icon fa-info-circle mt-1 mr-2"></span>
              {{'edit-profile.profile-details-disabled'|translate}}
            </p>
            <ng-container *ngFor="let field of contactFields">
              <div
                *ngIf="field.key === 'phoneNumber'"
                app-form-input
                field="phoneNumber"
                autocomplete="tel"
                label="{{'edit-profile.phone-number.label'|translate}}"
                [readonly]="field.readonly"
                [isInvalid]="isFieldInvalid('contact.phoneNumber')"
                [isValid]="isFieldValid('contact.phoneNumber')"
              >
                <div
                  app-form-input-feedback
                  type="invalid"
                >{{'edit-profile.phone-number.error-message.required'|translate}}</div>
              </div>
              <div
                [formGroup]="profileForm.get('contact').get('address')"
                *ngIf="field.key === 'address-streetAddress'"
              >
                <div
                  app-form-input
                  field="streetAddress"
                  autocomplete="street-address"
                  label="{{'edit-profile.street-address.label'|translate}}"
                  [readonly]="field.readonly"
                  [isInvalid]="isFieldInvalid('contact.address.streetAddress')"
                  [isValid]="isFieldValid('contact.address.streetAddress')"
                >
                  <div
                    app-form-input-feedback
                    type="invalid"
                  >{{'edit-profile.street-address.error-message.required'|translate}}</div>
                </div>
              </div>
              <div
                [formGroup]="profileForm.get('contact').get('address')"
                *ngIf="field.key === 'address-postalCode'"
              >
                <div
                  app-form-input
                  field="postalCode"
                  autocomplete="postal-code"
                  label="{{'edit-profile.postal-code.label'|translate}}"
                  [readonly]="field.readonly"
                  [isInvalid]="isFieldInvalid('contact.address.postalCode')"
                  [isValid]="isFieldValid('contact.address.postalCode')"
                >
                  <div
                    app-form-input-feedback
                    type="invalid"
                  >{{'edit-profile.postal-code.error-message.required'|translate}}</div>
                </div>
              </div>
              <div
                [formGroup]="profileForm.get('contact').get('address')"
                *ngIf="field.key === 'address-region'"
              >
                <div
                  app-form-input
                  field="region"
                  type="select"
                  label="{{'edit-profile.region.label'|translate}}"
                  [readonly]="field.readonly"
                  [isInvalid]="isFieldInvalid('contact.address.region')"
                  [isValid]="isFieldValid('contact.address.region')"
                  [ngClass]="{
                    'd-none': !showRegion()
                  }"
                  [selectOptions]="regionOptions"
                >
                  <div
                    app-form-input-feedback
                    type="invalid"
                  >{{'edit-profile.region.error-message.required'|translate}}</div>
                </div>
              </div>
              <div
                [formGroup]="profileForm.get('contact').get('address')"
                *ngIf="field.key === 'address-locality'"
              >
                <div
                  app-form-input
                  field="locality"
                  autocomplete="address-level2"
                  label="{{'edit-profile.locality.label'|translate}}"
                  [readonly]="field.readonly"
                  [isInvalid]="isFieldInvalid('contact.address.locality')"
                  [isValid]="isFieldValid('contact.address.locality')"
                >
                  <div
                    app-form-input-feedback
                    type="invalid"
                  >{{'edit-profile.locality.error-message.required'|translate}}</div>
                </div>
              </div>
              <div
                [formGroup]="profileForm.get('contact').get('address')"
                *ngIf="field.key === 'address-country'"
              >
                <div
                  app-form-input
                  field="country"
                  type="select"
                  autocomplete="country"
                  label="{{'edit-profile.country.label'|translate}}"
                  [readonly]="field.readonly"
                  [isInvalid]="isFieldInvalid('contact.address.country')"
                  [isValid]="isFieldValid('contact.address.country')"
                  [selectOptions]="resolveCountryOptions({label: resolveSelectCountryLabel(), value: null})"
                >
                  <div
                    app-form-input-feedback
                    type="invalid"
                  >{{'edit-profile.country.error-message.required'|translate}}</div>
                </div>
              </div>
            </ng-container>
          </fieldset>
        </div>
        <div #loginTab id="login" class="tab-pane  {{activeTab === 'login' ? 'active' : ''}}" >
          <fieldset
            class="pt-3"
            [formGroup]="profileForm.get('login')"
          >
            <p class="text-info d-flex flex-row px-2"
               *ngIf="hasDelegatedAuthentication"
            >
              <span class="fa fa-fw icon fa-info-circle mt-1 mr-2"></span>
              {{'edit-profile.login-details-disabled'|translate}}
            </p>
            <ng-container *ngFor="let field of loginFields">
              <div
                *ngIf="field.key === 'email'"
                app-form-input
                field="email"
                autocomplete="email"
                label="{{'edit-profile.email.label'|translate}}"
                readonly
                [append]="field.readonly === true ? undefined : email_append"
                [prepend]="email_prepend"
              >
                <ng-template #email_prepend>
                  <span [className]="'icon input-group-text text-white bg-success'">
                    <span class="fa fa-fw fa-user"></span>
                  </span>
                </ng-template>
                <ng-template #email_append>
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="changeEmail()"
                  >
                    <span class="icon fa fa-fw fa-pencil-alt"></span>
                    <span class="no-icon">
                      {{'edit-profile.email.change-button.label'|translate}}
                    </span>
                  </button>
                </ng-template>
              </div>
              <div
                *ngIf="field.key === 'email' && !field.readonly && hasRecoveryField"
                [className]="'recovery-emails mb-4 mx-1 small font-italic text-muted mt-n4'"
              >
                <ng-container *ngIf="profileForm.get('login').get('recoveryEmail').value !== ''">
                  <span [className]="'font-weight-bold'">{{'edit-profile.recovery-email.label'|translate}}</span>
                  <span>
                    {{profileForm.get('login').get('recoveryEmail').value}}
                  </span>
                  <a
                    href="#"
                    role="button"
                    [className]="'font-weight-bold'"
                    (click)="changeEmail()"
                  >
                    {{'edit-profile.recovery-email.change-button.label'|translate}}
                  </a>
                </ng-container>
                <a
                  *ngIf="profileForm.get('login').get('recoveryEmail').value === ''"
                  href="#"
                  role="button"
                  [className]="'font-weight-bold'"
                  (click)="changeEmail()"
                ><span class="fa fa-fw fa-plus-circle icon mr-1"></span>{{'edit-profile.recovery-email.add-button.label'|translate}}
                </a>
              </div>
              <div
                *ngIf="field.key === 'password'"
                app-form-input
                field="password"
                autocomplete="password"
                label="{{'edit-profile.password.label'|translate}}"
                readonly
                [prepend]="pwd_prepend"
                [append]="pwd_append"
              >
                <ng-template #pwd_prepend>
                  <span class="icon input-group-text bg-success text-white">
                    <span class="fa fa-fw fa-unlock-alt"></span>
                  </span>
                </ng-template>
                <ng-template #pwd_append>
                  <button
                    data-test-change-pwd-btn
                    type="button"
                    class="btn btn-primary"
                    (click)="changePWD()"
                  >
                    <span class="icon fa fa-fw fa-pencil-alt"></span>
                    <span class="no-icon">{{'edit-profile.password.change-button.label'|translate}}</span>
                  </button>
                </ng-template>
              </div>
              <div
                attr.data-test-totp="{{totpActive ? 'enabled' : 'disabled'}}"
                *ngIf="field.key === 'totp'"
                app-form-input
                field="totp"
                label="{{'edit-profile.totp.label'|translate}}"
                readonly
                [prepend]="totp_prepend"
                [append]="totp_append"
              >
                <ng-template #totp_prepend>
                  <span [className]="'icon input-group-text text-white ' + (totpActive ? 'bg-success' : 'bg-danger')">
                    <span class="fa fa-fw fa-shield-alt"></span>
                  </span>
                </ng-template>
                <ng-template #totp_append>
                  <button
                    data-test-configure-totp-btn
                    type="button"
                    class="btn btn-primary"
                    (click)="configureTotp()"
                  >
                    <span class="icon fa fa-fw fa-pencil-alt"></span>
                    <span class="no-icon">{{'edit-profile.totp.configure-button.label'|translate}}</span>
                  </button>
                </ng-template>
              </div>
            </ng-container>
          </fieldset>
        </div>
      </div>
      <app-duke-alert
        type="danger"
        *ngIf="hasError()"
        alertClass="mb-0"
        dismissible="true"
        (open)="alertHandler.onOpen($event);"
        (close)="alertHandler.onClose($event); submitFailed = false;"
      >
        <p class="mb-0">
          {{'edit-profile.submit-error.message'|translate}}
        </p>
      </app-duke-alert>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <div
          placement="top-right auto"
          container="body"
          *ngIf="hasEditableFields"
          [ngbTooltip]="!profileForm.valid ? ('edit-profile.apply-button.tooltip.invalid'|translate) : (!profileForm.dirty ? ('edit-profile.apply-button.tooltip.no-changes'|translate) : null)"
        >
          <button
            data-test-submit-button
            class="btn btn-success"
            type="submit"
            [disabled]="!profileForm.valid || !profileForm.dirty"
          >
            {{'edit-profile.apply-button.label'|translate}}
          </button>
        </div>
        <span
          class="text-danger order-2 mr-2 text-right"
          *ngIf="hasError()"
          [@cardFooterFeedbackTransition]
          [@.disabled]="disableAnimations()"
        >
          <span class="icon fa fa-fw fa-exclamation-triangle"></span>
          <span>
            {{'edit-profile.submit-error.notification'|translate}}
          </span>
        </span>
        <button
          type="button"
          data-test-cancel-button
          (click)="cancel()"
          [class]="'btn ' + (hasEditableFields ? 'btn-secondary order-3 mr-auto' : 'btn-primary')"
        >
          {{ profileForm.dirty ? ('edit-profile.cancel-button.label'|translate) : ('edit-profile.close-button.label'|translate)}}
        </button>
      </div>
    </div>
  </form>
</div>

<app-duke-alert
  data-test-verify-email-completed-success-message
  type="success"
  *ngIf="notification?.key === getAppConstant('SN_LOGIN_EMAIL_VALIDATED')"
  alertClass="mb-0"
  dismissible="true"
  (open)="alertHandler.onOpen($event);"
  (close)="alertHandler.onClose($event); hideNotification();"
>
  <p class="mb-0">
    {{'manage-emails.verify-email-completed-success.message'|translate}}
  </p>
</app-duke-alert>
<app-duke-alert
  data-test-change-password-completed-success-message
  type="success"
  *ngIf="notification?.key === getAppConstant('SN_PASSWORD_CHANGED')"
  alertClass="mb-0"
  dismissible="true"
  (open)="alertHandler.onOpen($event);"
  (close)="alertHandler.onClose($event); hideNotification();"
>
  <p class="mb-0">
    {{'change-password.password-change-completed-success.message'|translate}}
  </p>
</app-duke-alert>
<app-duke-alert
  data-test-load-agreements-failed
  type="danger"
  *ngIf="agreementLoadingFailed && !agreementLoadingFailedDismissed"
  alertClass="mb-0"
  dismissible="true"
  (open)="alertHandler.onOpen($event);"
  (close)="alertHandler.onClose($event); agreementLoadingFailedDismissed = true;"
>
  <p class="mb-0">
    {{'view-profile.agreement.loading-agreements-failed'|translate}}
  </p>
</app-duke-alert>
