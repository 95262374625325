<div
  class="card standalone fade view-profile-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <div class="card-header text-center">
    <h1 class="mb-0">
      <span class="profile-picture mb-2">{{resolveDisplayName().length ? resolveDisplayName().substr(0, 1) : resolveDisplayName()}}</span>
      <br />
      {{'view-profile.title'|translate}}
      <span>{{resolveDisplayName()}}</span>
    </h1>
  </div>
  <div class="card-body">
    <div class="card-deck">
      <a
        data-test-profile
        class="card mr-sm-1"
        [routerLink]="getAppConstant('PATH_EDIT_PROFILE')"
        fragment="profile"
      >
        <div class="card-header text-center">
          <h2 class="mb-0 h4">
            <span class="d-block mx-auto mb-2 icon highlight fa fa-2x fa-fw fa-user"></span>
            <span [innerHTML]="'view-profile.personal-information-label'|translate"></span>
          </h2>
        </div>
        <div class="card-body py-0 small">
          <ul class="list-unstyled">
            <li
              *ngIf="profile && (profile.firstName || profile.nickname || profile.lastName)"
              class="d-flex flex-row align-items-start mb-1"
            >
              <div class="icon mr-2 flex-shrink-0">
                <span class="fa fa-fw fa-user"></span>
              </div>
              <div>
                <strong
                  *ngIf="profile && includes(personalFields, 'firstName') && profile.firstName"
                >{{profile.firstName}} </strong>
                <em
                  *ngIf="profile && includes(personalFields, 'nickname') && profile.nickname"
                >&quot;{{profile.nickname}}&quot; </em>
                <strong
                  *ngIf="profile && includes(personalFields, 'lastName') && profile.lastName"
                >{{profile.lastName}}</strong>
              </div>
            </li>
            <li
              *ngIf="profile && includes(personalFields, 'professionalTitle') && profile.professionalTitle"
              class="d-flex flex-row align-items-start mb-1"
            >
              <div class="icon mr-2 flex-shrink-0">
                <span class="fa fa-fw fa-user-tie"></span>
              </div>
              <div>
                {{profile.professionalTitle}}
              </div>
            </li>
          </ul>
        </div>
        <div class="card-footer text-right pt-0">
          <span class="visual-link">
            <span class="icon fa fa-fw fa-pencil-alt"></span>
            {{'view-profile.edit-link-label'|translate}}
          </span>
        </div>
      </a>
      <a
        data-test-contact
        *ngIf="contactFields.length > 0"
        class="card mx-sm-1"
        [routerLink]="getAppConstant('PATH_EDIT_PROFILE')"
        fragment="contact"
      >
        <div class="card-header text-center">
          <h2 class="mb-0 h4">
            <span class="d-block mx-auto mb-2 icon highlight fa fa-2x fa-fw fa-address-book"></span>
            <span [innerHTML]="'view-profile.contact-details-label'|translate"></span>
          </h2>
        </div>
        <div class="card-body py-0 small">
          <ul class="list-unstyled">
            <li
              *ngIf="profile && profile.email && includes(loginFields, 'email')"
              class="d-flex flex-row align-items-start mb-1"
            >
              <div class="icon mr-2 flex-shrink-0">
                <span class="fa fa-fw fa-at"></span>
              </div>
              <div [innerHTML]="addWordBreaks(profile.email)"></div>
            </li>
            <li
              *ngIf="profile && includes(contactFields, 'phoneNumber') && profile.phoneNumber"
              class="d-flex flex-row align-items-start mb-1"
            >
              <div class="icon mr-2 flex-shrink-0">
                <span class="fa fa-fw fa-phone"></span>
              </div>
              <div>{{profile.phoneNumber}}</div>
            </li>
            <li
              *ngIf="profile && profile.address && (includes(contactFields, 'address-streetAddress') || includes(contactFields, 'address-postalCode') || includes(contactFields, 'address-locality') || includes(contactFields, 'address-region') || includes(contactFields, 'address-country'))"
              class="d-flex flex-row align-items-start mb-1"
            >
              <div class="icon mr-2 flex-shrink-0">
                <span class="fa fa-fw fa-home"></span>
              </div>
              <ul class="list-unstyled">
                <li *ngIf="profile && profile.address && profile.address.streetAddress && includes(contactFields, 'address-streetAddress')">{{profile.address.streetAddress}}</li>
                <li *ngIf="profile && profile.address && ((profile.address.postalCode && includes(contactFields, 'address-postalCode')) || (profile.address.locality  && includes(contactFields, 'address-locality')))">
                  {{profile.address.postalCode && includes(contactFields, 'address-postalCode') ? profile.address.postalCode : ''}}{{
                  profile.address.postalCode && includes(contactFields, 'address-postalCode') && profile.address.locality && includes(contactFields, 'address-locality') ? ', ': ''}}
                  {{profile.address.locality && includes(contactFields, 'address-locality') ? profile.address.locality: ''}}
                </li>
                <li *ngIf="profile && profile.address && ((profile.address.region && includes(contactFields, 'address-region')) || (profile.address.country && includes(contactFields, 'address-country')))">
                  {{profile.address.region && includes(contactFields, 'address-region') ? getRegionName(profile.address.region) : ''}}{{
                  profile.address.region && includes(contactFields, 'address-region') && profile.address.country && includes(contactFields, 'address-county') ? ', ': ''}}
                  {{profile.address.country && includes(contactFields, 'address-country') ? (resolveCountryName(profile.address.country) | async) : ''}}
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="card-footer text-right pt-0">
          <span class="visual-link">
            <span class="icon fa fa-fw fa-pencil-alt"></span>
            {{'view-profile.edit-link-label'|translate}}
          </span>
        </div>
      </a>
      <a
        data-test-login
        class="card ml-sm-1"
        [routerLink]="getAppConstant('PATH_EDIT_PROFILE')"
        fragment="login"
      >
        <div class="card-header text-center">
          <h2 class="mb-0 h4">
            <span class="d-block mx-auto mb-2 icon highlight fa fa-2x fa-fw fa-user-shield"></span>
            <span [innerHTML]="'view-profile.login-details-label'|translate"></span>
          </h2>
        </div>
        <div class="card-body py-0 small">
          <ul class="list-unstyled">
            <li
              *ngIf="profile && profile.email && includes(loginFields, 'email')"
              class="d-flex flex-row align-items-start mb-1"
            >
              <div class="icon mr-2 flex-shrink-0">
                <span class="fa fa-fw fa-user"></span>
              </div>
              <div [innerHTML]="addWordBreaks(profile.email)"></div>
            </li>
            <li
              *ngIf="includes(loginFields, 'password')"
              class="d-flex flex-row align-items-start mb-1"
            >
              <div class="icon mr-2 flex-shrink-0">
                <span class="fa fa-fw fa-unlock-alt"></span>
              </div>
              <div>
                ********
              </div>
            </li>
            <li
              *ngIf="includes(loginFields, 'totp') && hasTOTP"
              class="d-flex flex-row align-items-start mb-1"
            >
              <div class="icon mr-2 flex-shrink-0">
                <span class="fa fa-fw fa-shield-alt"></span>
              </div>
              <div>
                {{'view-profile.totp-active-label'|translate}}
              </div>
            </li>
          </ul>
        </div>
        <div class="card-footer text-right pt-0">
          <span class="visual-link">
            <span class="icon fa fa-fw fa-pencil-alt"></span>
            {{'view-profile.edit-link-label'|translate}}
          </span>
        </div>
      </a>
    </div>
  </div>
  <div class="card-footer" *ngIf="!noAgreementsFound && !agreementLoadingFailed">
    <ul class="list-unstyled mb-0 agreements">


      <ng-container *ngFor="let agreement of userAgreements; let ind = index;">
        <li class="d-block"
            *ngIf="isAccepted(agreement)"
        >
          <span class="icon fa fa-fw fa-check text-muted mr-1"></span>
          <a *ngIf="agreement.location" href="{{agreement.location}}" rel="noopener" target="_blank">{{agreement.name}}</a>
          <span *ngIf="!agreement.location">
              {{agreement.name}}
            </span>
          <span class="text-muted small">
              {{'view-profile.agreement.accepted-tooltip' | translate: { date: getAgreementResponseTimeAsString(agreement) } }}
            </span>
        </li>
        <li class="d-block"
            *ngIf="isRejected(agreement)"
        >
          <span class="icon fa fa-fw fa-ban text-muted mr-1"></span>
          <a *ngIf="agreement.location" href="{{agreement.location}}" rel="noopener" target="_blank">{{agreement.name}}</a>
          <span *ngIf="!agreement.location">
              {{agreement.name}}
            </span>
          <span class="text-muted small">
              {{'view-profile.agreement.rejected-tooltip' | translate: { date: getAgreementResponseTimeAsString(agreement) } }}
            </span>
        </li>
        <li class="d-block"
            *ngIf="!isRejected(agreement) && !isAccepted(agreement)"
        >
          <span class="icon fa fa-fw fa-exclamation-circle text-muted mr-1"></span>
          <a *ngIf="agreement.location" href="{{agreement.location}}" rel="noopener" target="_blank">{{agreement.name}}</a>
          <span *ngIf="!agreement.location">
              {{agreement.name}}
            </span>
          <span class="text-muted small">
              {{'view-profile.agreement.new-agreement-tooltip' | translate}}
            </span>
        </li>
      </ng-container>
      <li class="d-block">
        <a
          [routerLink]="getAppConstant('PATH_MANAGE_AGREEMENTS')"
        >
          <strong>
            {{'view-profile.agreement.manage-link-label'|translate}}
          </strong>
        </a>
      </li>
    </ul>
  </div>
</div>

<app-duke-alert
  data-test-verify-email-completed-success-message
  type="success"
  *ngIf="notification?.key === getAppConstant('SN_LOGIN_EMAIL_VALIDATED')"
  alertClass="mb-0"
  dismissible="true"
  (open)="alertHandler.onOpen($event);"
  (close)="alertHandler.onClose($event); hideNotification();"
>
  <p class="mb-0">
    {{'manage-emails.verify-email-completed-success.message'|translate}}
  </p>
</app-duke-alert>
<app-duke-alert
  data-test-change-password-completed-success-message
  type="success"
  *ngIf="notification?.key === getAppConstant('SN_PASSWORD_CHANGED')"
  alertClass="mb-0"
  dismissible="true"
  (open)="alertHandler.onOpen($event);"
  (close)="alertHandler.onClose($event); hideNotification();"
>
  <p class="mb-0">
    {{'change-password.password-change-completed-success.message'|translate}}
  </p>
</app-duke-alert>
<app-duke-alert
  data-test-load-agreements-failed
  type="danger"
  *ngIf="agreementLoadingFailed && !agreementLoadingFailedDismissed"
  alertClass="mb-0"
  dismissible="true"
  (open)="alertHandler.onOpen($event);"
  (close)="alertHandler.onClose($event); agreementLoadingFailedDismissed = true;"
>
  <p class="mb-0">
    {{'view-profile.agreement.loading-agreements-failed'|translate}}
  </p>
</app-duke-alert>
