<div
  class="card standalone fade manage-emails-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <div class="card-header text-center">
    <h1 class="mb-0">
      {{'manage-emails.title'|translate}}
    </h1>
  </div>
  <div class="card-body">
    <ng-container *ngIf="allowChangeLogin && showRecoveryEmails && recoveryEmailData.length === 0">
      <app-duke-alert
        type="info"
        alertClass="mb-4"
      >
        <p>
          {{'manage-emails.no-recovery-emails.info-paragraph'|translate}}
        </p>
      </app-duke-alert>
    </ng-container>
    <ng-container *ngIf="allowChangeLogin && requireEmailValidation && hasOnlyInvalidRecoveryEmails() && (showRecoveryEmails || !!resolveActiveRecoveryEmail())">
      <app-duke-alert
        type="info"
        alertClass="mb-4"
      >
        <p>
          {{'manage-emails.only-invalid-recovery-emails.info-paragraph'|translate}}
        </p>
      </app-duke-alert>
    </ng-container>
    <form
      [formGroup]="currentEmailForm"
      *ngIf="showEmail"
    >
      <div
        app-form-input
        id="current-email"
        field="email"
        label="{{'manage-emails.current-email.label'|translate}}"
        type="text"
        [prepend]="current_email_prepend"
        readonly
        [append]="sendEmailValidation && !isCurrentEmailValidated ? [loginEmail_validate] : undefined"
      >
        <ng-template #loginEmail_validate>
          <button
            data-test-validate-login-email-btn
            type="button"
            class="btn btn-success"
            (click)="validateLoginEmail()"
            [ngbTooltip]="('manage-emails.recovery-email.validate-email-button.tooltip'|translate)"
          >
            <span class="icon fa fa-fw fa-check-circle"></span>
            <span class="no-icon">{{'manage-emails.recovery-email.validate-email-button.label'|translate}}</span>
          </button>
        </ng-template>
        <ng-template #current_email_prepend>
          <span [className]="'icon input-group-text text-white bg-primary'">
            <span class="fa fa-fw fa-user"></span>
          </span>
        </ng-template>
      </div>
    </form>

    <ng-container *ngIf="showRecoveryEmails">
      <hr class="mb-4" *ngIf="showEmail" />
      <ng-template #recoveryEmailEntry let-itm='itm' let-i="i">
        <form
          [formGroup]="itm.form"
        >
          <div
            app-form-input
            [id]="'recovery-email-' + (i + 1)"
            field="email"
            label="{{'manage-emails.recovery-email.label'|translate:{ index: (i + 1) } }}"
            type="text"
            readonly
            [prepend]="requireEmailValidation ? recoveryEmail_prepend : undefined"
            [append]="resolveRecoveryEmailButtons(itm, { delete: recoveryEmail_delete, validate: recoveryEmail_validate, setLogin: recoveryEmail_setLogin })"
          >
            <ng-template #recoveryEmail_prepend>
              <span
                *ngIf="!!itm.isValid"
                [className]="'icon input-group-text text-white bg-success'"
                [ngbTooltip]="('manage-emails.recovery-email.validated.tooltip'|translate)"
              >
                <span class="fa fa-fw fa-check-circle"></span>
              </span>
              <span
                *ngIf="!itm.isValid"
                [className]="'icon input-group-text text-white bg-warning'"
                [ngbTooltip]="('manage-emails.recovery-email.not-validated.tooltip'|translate)"
              >
                <span class="fa fa-fw fa-exclamation-circle"></span>
              </span>
            </ng-template>
            <ng-template #recoveryEmail_delete>
              <button
                data-test-delete-email-btn
                type="button"
                class="btn btn-danger"
                (click)="deleteEmail(itm)"
                [ngbTooltip]="('manage-emails.recovery-email.delete-email-button.tooltip'|translate)"
              >
                <span class="icon fa fa-fw fa-trash-alt"></span>
                <span class="no-icon">{{'manage-emails.recovery-email.delete-email-button.label'|translate}}</span>
              </button>
            </ng-template>
            <ng-template #recoveryEmail_validate>
              <button
                data-test-validate-email-btn
                type="button"
                class="btn btn-success"
                (click)="validateRecoveryEmail(itm)"
                [ngbTooltip]="('manage-emails.recovery-email.validate-email-button.tooltip'|translate)"
              >
                <span class="icon fa fa-fw fa-check-circle"></span>
                <span class="no-icon">{{'manage-emails.recovery-email.validate-email-button.label'|translate}}</span>
              </button>
            </ng-template>
            <ng-template #recoveryEmail_setLogin>
              <button
                data-test-set-login-email-btn
                type="button"
                class="btn btn-primary"
                (click)="openChangeEmail(itm.form)"
                [ngbTooltip]="('manage-emails.recovery-email.set-login-email-button.tooltip'|translate)"
              >
                <span class="icon fa fa-fw fa-user-check"></span>
                <span class="no-icon">{{'manage-emails.recovery-email.set-login-email-button.label'|translate}}</span>
              </button>
            </ng-template>
          </div>
        </form>
      </ng-template>
      <ng-container *ngFor="let itm of recoveryEmailData; let i = index;">
        <ng-container [ngTemplateOutlet]="recoveryEmailEntry" [ngTemplateOutletContext]="{itm:itm, i:i}"></ng-container>
        <ng-container *ngIf="activeChangeEmail === itm.form" [ngTemplateOutlet]="confirmLoginEmailChange"></ng-container>
      </ng-container>
      <form
        [formGroup]="addEmailForm"
        (ngSubmit)="addEmail()"
      >
        <div
          app-form-input
          field="email"
          autofocus
          label="{{'manage-emails.new-email.label'|translate}}"
          type="text"
          autocomplete="email"
          [isInvalid]="isFieldInvalid(addEmailForm.get('email'))"
          [isValid]="isFieldValid(addEmailForm.get('email'))"
          [append]="addEmail_append"
        >
          <ng-template #addEmail_append>
            <button
              data-test-add-email-btn
              type="submit"
              class="btn btn-primary"
              [disabled]="!addEmailForm.valid || !addEmailForm.get('email').value"
              [ngbTooltip]="('manage-emails.new-email.add-email-button.tooltip'|translate)"
            >
              <span class="fa fa-fw fa-plus icon"></span>
              <span class="no-icon">{{'manage-emails.new-email.add-email-button.label'|translate}}</span>
            </button>
          </ng-template>
          <div
            app-form-input-feedback
            *ngIf="isFieldInvalid(addEmailForm.get('email'))"
            type="invalid"
          >
            {{'manage-emails.new-email.error-message.required-or-invalid'|translate}}
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="!showRecoveryEmails && allowChangeLogin">
      <hr class="mb-4" />
      <form
        *ngIf="!!resolveActiveRecoveryEmail() && (resolveActiveRecoveryEmail().isValid || !requireEmailValidation)"
        [formGroup]="resolveActiveRecoveryEmail().form"
      >
        <div
          app-form-input
          [id]="'pending-email'"
          field="email"
          label="{{'manage-emails.change-login-email.label'|translate}}"
          type="text"
          readonly
          [prepend]="requireEmailValidation ? noRecovery_prepend : undefined"
          [append]="[noRecovery_delete, noRecovery_setLogin]"
        >
          <ng-template #noRecovery_prepend>
              <span
                *ngIf="!!resolveActiveRecoveryEmail().isValid"
                [className]="'icon input-group-text text-white bg-success'"
                [ngbTooltip]="('manage-emails.recovery-email.validated.tooltip'|translate)"
              >
                <span class="fa fa-fw fa-check-circle"></span>
              </span>
            <span
              *ngIf="!resolveActiveRecoveryEmail().isValid"
              [className]="'icon input-group-text text-white bg-warning'"
              [ngbTooltip]="('manage-emails.recovery-email.not-validated.tooltip'|translate)"
            >
                <span class="fa fa-fw fa-exclamation-circle"></span>
              </span>
          </ng-template>
          <ng-template #noRecovery_setLogin>
            <button
              data-test-set-login-email-btn
              type="button"
              class="btn btn-primary"
              [disabled]="activeChangeEmail === resolveActiveRecoveryEmail().form"
              (click)="openChangeEmail(resolveActiveRecoveryEmail().form)"
              [ngbTooltip]="('manage-emails.recovery-email.set-login-email-button.tooltip'|translate)"
            >
              <span class="fa fa-fw fa-user-check"></span>
              <span class="no-icon">{{'manage-emails.recovery-email.set-login-email-button.label'|translate}}</span>
            </button>
          </ng-template>
          <ng-template #noRecovery_delete>
            <button
              data-test-delete-email-btn
              type="button"
              class="btn btn-danger"
              (click)="deleteEmail(resolveActiveRecoveryEmail())"
              [ngbTooltip]="('manage-emails.recovery-email.delete-email-button.tooltip'|translate)"
            >
              <span class="icon fa fa-fw fa-trash-alt"></span>
              <span class="no-icon">{{'manage-emails.recovery-email.delete-email-button.label'|translate}}</span>
            </button>
          </ng-template>
        </div>
      </form>
      <form
        *ngIf="!!resolveActiveRecoveryEmail() && !resolveActiveRecoveryEmail().isValid && requireEmailValidation"
        [formGroup]="resolveActiveRecoveryEmail().form"
      >
        <div
          app-form-input
          [id]="'pending-email'"
          field="email"
          label="{{'manage-emails.change-login-email.label'|translate}}"
          type="text"
          readonly
          [prepend]="noRecovery_prepend"
          [append]="sendEmailValidation ? [noRecovery_delete, noRecovery_validate] : noRecovery_delete"
        >
          <ng-template #noRecovery_prepend>
              <span
                *ngIf="!!resolveActiveRecoveryEmail().isValid"
                [className]="'icon input-group-text text-white bg-success'"
                [ngbTooltip]="('manage-emails.recovery-email.validated.tooltip'|translate)"
              >
                <span class="fa fa-fw fa-check-circle"></span>
              </span>
            <span
              *ngIf="!resolveActiveRecoveryEmail().isValid"
              [className]="'icon input-group-text text-white bg-warning'"
              [ngbTooltip]="('manage-emails.recovery-email.not-validated.tooltip'|translate)"
            >
                <span class="fa fa-fw fa-exclamation-circle"></span>
              </span>
          </ng-template>
          <ng-template #noRecovery_validate>
            <button
              data-test-validate-email-btn
              type="button"
              class="btn btn-success"
              (click)="validateRecoveryEmail(resolveActiveRecoveryEmail())"
              [ngbTooltip]="('manage-emails.recovery-email.validate-email-button.tooltip'|translate)"
            >
              <span class="icon fa fa-fw fa-check-circle"></span>
              <span class="no-icon">{{'manage-emails.recovery-email.validate-email-button.label'|translate}}</span>
            </button>
          </ng-template>
          <ng-template #noRecovery_delete>
            <button
              data-test-delete-email-btn
              type="button"
              class="btn btn-danger"
              (click)="deleteEmail(resolveActiveRecoveryEmail())"
              [ngbTooltip]="('manage-emails.recovery-email.delete-email-button.tooltip'|translate)"
            >
              <span class="icon fa fa-fw fa-trash-alt"></span>
              <span class="no-icon">{{'manage-emails.recovery-email.delete-email-button.label'|translate}}</span>
            </button>
          </ng-template>
        </div>
      </form>
      <form
        *ngIf="!resolveActiveRecoveryEmail()"
        [formGroup]="addEmailForm"
        (ngSubmit)="addEmail()"
      >
        <div
          app-form-input
          field="email"
          id="add-email"
          autofocus
          label="{{'manage-emails.change-login-email.label'|translate}}"
          type="text"
          autocomplete="email"
          [isInvalid]="isFieldInvalid(addEmailForm.get('email'))"
          [isValid]="isFieldValid(addEmailForm.get('email'))"
          [append]="addEmail_append"
        >
          <ng-template #addEmail_append>
            <button
              data-test-add-email-btn
              type="submit"
              class="btn btn-primary"
              [disabled]="!addEmailForm.valid || !addEmailForm.get('email').value"
            >{{'manage-emails.change-login-email.change-email-button.label'|translate}}
            </button>
          </ng-template>
          <div
            app-form-input-feedback
            *ngIf="isFieldInvalid(addEmailForm.get('email'))"
            type="invalid"
          >
            {{'manage-emails.change-login-email.error-message.required-or-invalid'|translate}}
          </div>
        </div>
      </form>
      <ng-container *ngIf="!!resolveActiveRecoveryEmail() && activeChangeEmail === resolveActiveRecoveryEmail().form" [ngTemplateOutlet]="confirmLoginEmailChange"></ng-container>
    </ng-container>
  </div>
  <div class="card-footer">
    <div class="d-flex align-items-center flex-row-reverse">
      <a
        data-test-done-btn
        href="javascript:void(0);"
        [routerLink]="getAppConstant('PATH_EDIT_PROFILE')"
        fragment="login"
        queryParamsHandling="merge"
        class="btn btn-primary"
        autofocus
      >
        {{'manage-emails.close-button.label'|translate}}
      </a>
    </div>
  </div>
  <!-- alerts -->
  <app-duke-alert
    data-test-set-login-email-error-message
    type="danger"
    *ngIf="notification?.key === 'setLoginEmailFailed'"
    alertClass="mb-0"
    dismissible="true"
    (open)="alertHandler.onOpen($event);"
    (close)="alertHandler.onClose($event); hideNotification();"
  >
    <p class="mb-0">
      {{'manage-emails.set-login-email-error.message'|translate}}
    </p>
  </app-duke-alert>
  <app-duke-alert
    data-test-set-login-email-success-message
    type="success"
    *ngIf="notification?.key === 'setLoginEmailSuccess'"
    alertClass="mb-0"
    dismissible="true"
    (open)="alertHandler.onOpen($event);"
    (close)="alertHandler.onClose($event); hideNotification();"
  >
    <p class="mb-0">
      {{'manage-emails.set-login-email-success.message'|translate}}
    </p>
  </app-duke-alert>
  <app-duke-alert
    data-test-verify-email-completed-success-message
    type="success"
    *ngIf="notification?.key === getAppConstant('SN_RECOVERY_EMAIL_VALIDATED')"
    alertClass="mb-0"
    dismissible="true"
    (open)="alertHandler.onOpen($event);"
    (close)="alertHandler.onClose($event); hideNotification();"
  >
    <p class="mb-0">
      {{'manage-emails.verify-email-completed-success.message'|translate}}
    </p>
  </app-duke-alert>
  <app-duke-alert
    data-test-verify-email-error-message
    type="danger"
    *ngIf="notification?.key === 'verifyEmailFailed'"
    alertClass="mb-0"
    dismissible="true"
    (open)="alertHandler.onOpen($event);"
    (close)="alertHandler.onClose($event); hideNotification();"
  >
    <p class="mb-0">
      {{'manage-emails.verify-email-error.message'|translate}}
    </p>
  </app-duke-alert>
  <app-duke-alert
    data-test-verify-email-success-message
    type="success"
    *ngIf="notification?.key === 'verifyEmailSuccess'"
    alertClass="mb-0"
    dismissible="true"
    (open)="alertHandler.onOpen($event);"
    (close)="alertHandler.onClose($event); hideNotification();"
  >

    <p
      [innerHTML]="'validate-email.info.key-sent-copy-without-code'|translate: { email: '<strong>' + notification.data.email + '</strong>'}"
    ></p>
    <p class="mb-0">
      {{'validate-email.info.resend-key-copy'|translate}}
    </p>
  </app-duke-alert>
  <app-duke-alert
    data-test-delete-email-error-message
    type="danger"
    *ngIf="notification?.key === 'deleteEmailFailed'"
    alertClass="mb-0"
    dismissible="true"
    (open)="alertHandler.onOpen($event);"
    (close)="alertHandler.onClose($event); hideNotification();"
  >
    <p class="mb-0">
      {{'manage-emails.delete-email-error.message'|translate}}
    </p>
  </app-duke-alert>
  <app-duke-alert
    data-test-add-email-error-message
    type="danger"
    *ngIf="this.notification?.key === 'addEmailFailed'"
    alertClass="mb-0"
    dismissible="true"
    (open)="alertHandler.onOpen($event);"
    (close)="alertHandler.onClose($event); hideNotification();"
  >
    <p class="mb-0">
      {{'manage-emails.add-email-error.message'|translate}}
    </p>
  </app-duke-alert>
</div>
<!-- /alerts -->

<!-- templates -->
<ng-template
  #confirmLoginEmailChange
>
  <app-duke-alert
    type="warning"
    icon="fa-user-check"
    alertClass="mb-4 mt-n3"
  >
    <form
      [formGroup]="setLoginEmailForm"
      (ngSubmit)="submitSetLoginEmailForm()"
    >
      <p>
        {{'manage-emails.confirm-login-email-change.info-paragraph'|translate}}
      </p>
      <div
        app-form-input
        autocomplete="current-password"
        field="password"
        label="{{'manage-emails.confirm-login-email-change.password.label'|translate}}"
        type="password"
        [append]="[confirmButtons]"
      ></div>
      <ng-template #confirmButtons>
        <button
          data-test-cancel-login-change-button
          type="button"
          class="btn btn-secondary"
          (click)="closeChangeEmail()"
        >{{'manage-emails.confirm-login-email-change.cancel-button.label'|translate}}</button>
        <button
          data-test-change-login-button
          type="submit"
          class="btn btn-primary"
        >{{'manage-emails.confirm-login-email-change.change-button.label'|translate}}</button>
      </ng-template>
    </form>
  </app-duke-alert>
</ng-template>
<!-- /templates -->
